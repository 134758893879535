// @flow

import React, { Component } from 'react';
import { Field, FormSection } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { upperFirst, toLower } from 'lodash';

import { HeaderComponent } from 'app/core';
import {
  AlertComponent,
  ButtonComponent,
  CardComponent,
  ConnectedInputComponent,
  ConnectedCheckboxesComponent,
  ConnectedToggleComponent,
  ConnectedTextareaComponent,
  ConnectedSelectComponent,
  ExternalLinkComponent,
  ConnectedPhoneNumberInputComponent,
  ConnectedUrlComponent,
} from 'app/shared';
import { PersonalSectionContainer } from 'app/register/shared';
import { BenefitsContainer } from 'app/common/venues/benefits/benefits.container';

import type { MaraNumProps, Props, State } from './register-service-form.component.types';

const smartumCheckoutDict = {
  en: process.env.REACT_APP_SMARTUM_CHECKOUT_EN,
  fi: process.env.REACT_APP_SMARTUM_CHECKOUT_FI,
  sv: process.env.REACT_APP_SMARTUM_CHECKOUT_SV,
};

const smartumPlusBuyerRegisterDict = {
  en: process.env.REACT_APP_SMARTUM_PLUS_BUYER_REGISTER_EN,
  fi: process.env.REACT_APP_SMARTUM_PLUS_BUYER_REGISTER_FI,
  sv: process.env.REACT_APP_SMARTUM_PLUS_BUYER_REGISTER_SV,
};
export class RegisterServiceFormComponent extends Component<Props, State> {
  state = {
    selectedBenefit: this.props.benefit,
    settlementReference: '',
    settlementMessage: '',
  };

  onBenefitChange = (event: any, value: string) => {
    this.setState({ selectedBenefit: value });
  };

  translateServices = (services: { id: string, name: string }[]): { id: string, name: string }[] => {
    if (!services) {
      return [];
    }

    if (this.state.selectedBenefit === 'massage') {
      services = services.filter(s => s.name !== 'Physiotherapy');
    }
    return services.map(service => ({
      ...service,
      name: this.props.intl.formatMessage({ id: `VENUES.SERVICE.${service.name}`, defaultMessage: service.name }),
    }));
  };

  getSettlementOptions = () =>
    this.props.settlements
      ? this.props.settlements.map(({ id, name }) => ({
          id,
          name: this.props.intl.formatMessage({ id: `SETTLEMENT.${name}` }),
        }))
      : [];

  maraNumComponent = ((props: MaraNumProps) =>
    'lunch' === props.benefit && (
      <FormSection name="address">
        <div className="col-lg-6">
          <Field
            name="maraNum"
            component={ConnectedToggleComponent}
            format={v => !!v}
            label={this.props.intl.formatMessage({ id: 'REGISTER.MARA_NUM' })}
            placeholder={this.props.intl.formatMessage({ id: 'REGISTER.MARA_NUM' })}
          />
        </div>
      </FormSection>
    ): Function);

  render() {
    // https://smartumoy.atlassian.net/browse/SD-4823
    // Hide WIREDEMM as swift code options in service provider registration form because it is a bank outside of Finland.
    const optionsExcludingWIREDEMM = Object.entries(this.props.swiftCodes)
      .filter(swiftCode => swiftCode[0] !== 'WIREDEMM')
      .map(([id, name]) => ({ id, name }));
    const paymentMethods = this.props.paymentMethods.filter(item => !item.legacy);

    return (
      <section>
        <HeaderComponent
          breadcrumbs={[
            {
              icon: 'home-icon-silhouette',
            },
            {
              name: <FormattedMessage id="REGISTER.TITLE" />,
            },
          ]}
        >
          <FormattedMessage id="REGISTER.TITLE" tagName="h2" />
          <FormattedMessage id="REGISTER.DESCRIPTION_PT" tagName="p" />
        </HeaderComponent>
        <CardComponent>
          <div className="card-header">
            {!this.props.isValid && (
              <AlertComponent type="danger" className="text-left">
                <FormattedMessage id="VALIDATION.FORM_ERROR" tagName="p" />
              </AlertComponent>
            )}
            <AlertComponent type="light" className="text-left">
              <FormattedMessage
                id="REGISTER.INSTRUCTION_BOX.WELCOME_SMARTUM_NETWORK.INFO"
                values={{
                  link: (
                    <ExternalLinkComponent href={smartumPlusBuyerRegisterDict[this.props.intl.locale]} target="_blank">
                      <FormattedMessage id="REGISTER.INSTRUCTION_BOX.WELCOME_SMARTUM_NETWORK.HERE" />
                    </ExternalLinkComponent>
                  ),
                  bikeLink: (
                    <ExternalLinkComponent href="https://www.smartum.fi/pyoraliikkeille/liity" target="_blank">
                      <FormattedMessage id="REGISTER.INSTRUCTION_BOX.WELCOME_SMARTUM_NETWORK.HERE" />
                    </ExternalLinkComponent>
                  ),
                }}
                tagName="p"
              />
            </AlertComponent>
            <h4 className="card-title">
              <FormattedMessage id="REGISTER.SECTION_TITLES.EMPLOYEE_BENEFITS" />
            </h4>
            <AlertComponent type="light" className="text-left mt-4 mb-0">
              <FormattedMessage id="REGISTER.INSTRUCTION_BOX.SERVICE_PROVIDER_DETAILS" tagName="p" />
            </AlertComponent>
          </div>
          <div className="card-body">
            <Field
              name="benefit"
              onChange={this.onBenefitChange}
              component={BenefitsContainer}
              locale={this.props.intl.locale}
            />
          </div>
          <div className="card-header card-header--between">
            <h4 className="card-title">
              <FormattedMessage id="REGISTER.SECTION_TITLES.GENERAL_COMPANY" />
            </h4>
            <AlertComponent type="light" className="text-left mt-4 mb-0">
              <FormattedMessage id="REGISTER.INSTRUCTION_BOX.GENERAL_COMPANY_DETAILS" tagName="p" />
            </AlertComponent>
          </div>
          <div className="card-body">
            <FormSection name="company">
              <div className="row">
                <div className="col-lg-6">
                  <Field
                    name="name"
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'REGISTER.OFFICIAL_NAME' })}
                    placeholder={this.props.intl.formatMessage({ id: 'REGISTER.OFFICIAL_NAME' })}
                    required
                    normalize={upperFirst}
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="businessId"
                    required
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'REGISTER.BUSINESS_ID' })}
                    placeholder={this.props.intl.formatMessage({ id: 'REGISTER.BUSINESS_ID' })}
                    addon={this.props.intl.formatMessage({ id: 'REGISTER.BUSINESS_ID_ADDON' })}
                  />
                </div>
                <FormSection name="address">
                  <div className="col-lg-6">
                    <Field
                      name="address"
                      component={ConnectedInputComponent}
                      label={this.props.intl.formatMessage({ id: 'REGISTER.STREET_ADDRESS' })}
                      placeholder={this.props.intl.formatMessage({ id: 'REGISTER.STREET_ADDRESS' })}
                      required
                      normalize={upperFirst}
                    />
                  </div>
                </FormSection>
                <FormSection name="address">
                  <div className="col-lg-6">
                    <Field
                      name="zipCode"
                      component={ConnectedInputComponent}
                      label={this.props.intl.formatMessage({ id: 'REGISTER.ZIP_CODE' })}
                      placeholder={this.props.intl.formatMessage({ id: 'REGISTER.ZIP_CODE' })}
                      required
                    />
                  </div>
                </FormSection>
                <FormSection name="address">
                  <div className="col-lg-6">
                    <Field
                      name="city"
                      component={ConnectedInputComponent}
                      label={this.props.intl.formatMessage({ id: 'REGISTER.CITY' })}
                      placeholder={this.props.intl.formatMessage({ id: 'REGISTER.CITY' })}
                      required
                      normalize={upperFirst}
                    />
                  </div>
                </FormSection>
                <div className="col-lg-6">
                  <Field
                    name="phone"
                    required
                    component={ConnectedPhoneNumberInputComponent}
                    label={this.props.intl.formatMessage({ id: 'REGISTER.PHONE' })}
                    placeholder={this.props.intl.formatMessage({ id: 'REGISTER.PHONE' })}
                  />
                </div>
                <Field name="maraNum" benefit={this.props.benefit} component={this.maraNumComponent} />
              </div>
            </FormSection>
          </div>
          <div className="card-header card-header--between">
            <h4 className="card-title">
              <FormattedMessage id="REGISTER.SECTION_TITLES.BANK_ACCOUNT" />
            </h4>
          </div>
          <div className="card-body">
            <FormSection name="bankAccount">
              <div className="row">
                <div className="col-lg-6">
                  <Field
                    name="iban"
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'REGISTER.ACCOUNT_NUMBER' })}
                    placeholder={this.props.intl.formatMessage({ id: 'REGISTER.ACCOUNT_NUMBER' })}
                    required
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="swift"
                    required
                    component={ConnectedSelectComponent}
                    options={optionsExcludingWIREDEMM}
                    label={this.props.intl.formatMessage({ id: 'REGISTER.SWIFT' })}
                    placeholder={this.props.intl.formatMessage({ id: 'REGISTER.SWIFT' })}
                  />
                </div>
              </div>
            </FormSection>
          </div>
          <PersonalSectionContainer name="responsiblePerson">
            <h4 className="card-title">
              <FormattedMessage id="REGISTER.SECTION_TITLES.PERSON_RESPONSIBLE" />
            </h4>
            <AlertComponent type="light" className="text-left mt-4 mb-0">
              <FormattedMessage id="REGISTER.INSTRUCTION_BOX.PERSON_RESPONSIBLE_INFO" tagName="p" />
            </AlertComponent>
          </PersonalSectionContainer>
          <div className="card-header card-header--with-line card-header--with-icon">
            <i className="fi fi-business" />
            <h4 className="card-title">
              <FormattedMessage id="REGISTER.SECTION_TITLES.VENUE_GENERAL" />
            </h4>
          </div>
          <div className="card-body">
            <AlertComponent type="light" className="text-left">
              <FormattedMessage id="REGISTER.INSTRUCTION_BOX.VENUE_GENERAL_INFO" tagName="p" />
              <ButtonComponent onClick={() => this.props.fillValues('company', 'venue')} theme="outline-secondary">
                <FormattedMessage id="REGISTER.FILL_VALUES" />
              </ButtonComponent>
            </AlertComponent>

            <FormSection name="venue">
              <div className="row">
                <div className="col-lg-6">
                  <Field
                    name="name"
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.NAME' })}
                    placeholder={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.NAME' })}
                    addon={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.NAME_ADDON' })}
                    required
                    normalize={upperFirst}
                  />
                </div>
                <FormSection name="address">
                  <div className="col-lg-6">
                    <Field
                      name="address"
                      component={ConnectedInputComponent}
                      label={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.STREET_ADDRESS' })}
                      placeholder={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.STREET_ADDRESS' })}
                      required
                      normalize={upperFirst}
                    />
                  </div>
                </FormSection>
                <FormSection name="address">
                  <div className="col-lg-6">
                    <Field
                      name="zipCode"
                      component={ConnectedInputComponent}
                      label={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.ZIP_CODE' })}
                      placeholder={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.ZIP_CODE' })}
                      required
                    />
                  </div>
                </FormSection>
                <FormSection name="address">
                  <div className="col-lg-6">
                    <Field
                      name="city"
                      component={ConnectedInputComponent}
                      label={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.CITY' })}
                      placeholder={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.CITY' })}
                      required
                      normalize={upperFirst}
                    />
                  </div>
                </FormSection>
              </div>
              <div className="row mt-5">
                <div className="col-lg-6">
                  <Field
                    name="website"
                    component={ConnectedUrlComponent}
                    label={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.WEBSITE_ADDRESS' })}
                    placeholder={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.WEBSITE_ADDRESS' })}
                    normalize={toLower}
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="email"
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.EMAIL' })}
                    placeholder={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.EMAIL' })}
                    normalize={toLower}
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="phone"
                    component={ConnectedPhoneNumberInputComponent}
                    label={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.PHONE' })}
                    placeholder={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.PHONE' })}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <Field
                    name="description"
                    component={ConnectedTextareaComponent}
                    label={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.DESCRIPTION' })}
                    addon={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.DESCRIPTION_ADDON' })}
                  />
                </div>
              </div>

              <div className="card-header card-header--inner">
                <h4 className="card-title">
                  <FormattedMessage id="REGISTER.SECTION_TITLES.DELIVERY_ADDRESS" />
                </h4>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <Field
                    name="deliveryAddress.address"
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'REGISTER.STREET_ADDRESS' })}
                    placeholder={this.props.intl.formatMessage({ id: 'REGISTER.STREET_ADDRESS' })}
                    required
                    normalize={upperFirst}
                    addon={this.props.intl.formatMessage({ id: 'REGISTER.SECTION_ADDONS.MAILING_ADDRESS_ADDON' })}
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="deliveryAddress.zipCode"
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'REGISTER.ZIP_CODE' })}
                    placeholder={this.props.intl.formatMessage({ id: 'REGISTER.ZIP_CODE' })}
                    required
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="deliveryAddress.city"
                    component={ConnectedInputComponent}
                    label={this.props.intl.formatMessage({ id: 'REGISTER.CITY' })}
                    placeholder={this.props.intl.formatMessage({ id: 'REGISTER.CITY' })}
                    required
                    normalize={upperFirst}
                  />
                </div>
              </div>
              <ButtonComponent
                onClick={() => this.props.fillValues('venue.address', 'venue.deliveryAddress')}
                theme="outline-secondary"
              >
                <FormattedMessage id="REGISTER.FILL_VALUES" />
              </ButtonComponent>
            </FormSection>
          </div>
          <div className="card-header card-header--between">
            <h4 className="card-title">
              <FormattedMessage id="REGISTER.SECTION_TITLES.ACCEPTED_MEANS_PAYMENT" />
            </h4>
          </div>
          <div className="card-body">
            <Field
              name="acceptedPayments"
              locale={this.props.intl.locale}
              theme="extended"
              options={paymentMethods.map(payment => ({
                ...payment,
                name: `PAYMENT.${payment.name}.NAME`,
                description: `PAYMENT.${payment.description}.DESCRIPTION`,
              }))}
              isOptionInput={true}
              selectedBenefit={this.props.benefit}
              component={ConnectedCheckboxesComponent}
            />
          </div>
          <div className="card-body">
            <AlertComponent type="light" className="text-left">
              <FormattedMessage
                id="SMARTUM_CHECKOUT.INFO"
                values={{
                  link: (
                    <ExternalLinkComponent href={smartumCheckoutDict[this.props.intl.locale]} target="_blank">
                      <FormattedMessage id="SMARTUM_CHECKOUT.HERE" />
                    </ExternalLinkComponent>
                  ),
                }}
                tagName="p"
              />
            </AlertComponent>
          </div>
          <div className="card-header card-header--between">
            <h4 className="card-title">
              <FormattedMessage id="VENUES.SETTLEMENT_INFORMATION" />
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6">
                <Field
                  name="settlement"
                  required
                  component={ConnectedSelectComponent}
                  options={this.getSettlementOptions()}
                  label={this.props.intl.formatMessage({ id: 'VENUES.SETTLEMENT_FREQUENCY' })}
                  addon={this.props.intl.formatMessage({ id: 'REGISTER.SECTION_ADDONS.SETTLEMENT_INFO_ADDON' })}
                />
              </div>
              <div className="col-lg-6">
                <Field
                  name="settlementAmount"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'VENUES.SETTLEMENT_MINIMUM_SUM' })}
                  placeholder={this.props.intl.formatMessage({ id: 'VENUES.SETTLEMENT_MINIMUM_SUM' })}
                  addon={this.props.intl.formatMessage({ id: 'REGISTER.SECTION_ADDONS.SETTLEMENT_MIN_SUM_ADDON' })}
                />
              </div>
              <div className="col-lg-6">
                <Field
                  name="settlementReference"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.SETTLEMENT_REFERENCE' })}
                  placeholder={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.SETTLEMENT_REFERENCE' })}
                  disabled={this.state.settlementMessage}
                  onChange={e => this.setState({ settlementReference: e.target.value })}
                  type="number"
                  addon={this.props.intl.formatMessage({ id: 'REGISTER.SECTION_ADDONS.VENUE_SETTLEMENT_REF_ADDON' })}
                />
              </div>
              <div className="col-lg-6">
                <Field
                  name="settlementMessage"
                  component={ConnectedInputComponent}
                  label={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.SETTLEMENT_MESSAGE' })}
                  placeholder={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.SETTLEMENT_MESSAGE' })}
                  disabled={this.state.settlementReference}
                  onChange={e => this.setState({ settlementMessage: e.target.value })}
                  addon={this.props.intl.formatMessage({ id: 'REGISTER.SECTION_ADDONS.VENUE_SETTLEMENT_MSG_ADDON' })}
                />
              </div>
            </div>
          </div>
          <div className="card-header card-header--between">
            <h4 className="card-title">
              <FormattedMessage id="REGISTER.SECTION_TITLES.VENUE_CATEGORY" />
            </h4>
          </div>
          <div className="card-body">
            <Field
              name="services"
              locale={this.props.intl.locale}
              options={this.translateServices(this.props.services[this.props.benefit])}
              component={ConnectedCheckboxesComponent}
              translate={false}
            />
          </div>
          <div className="card-footer text-right">
            <ButtonComponent theme="primary" size="large" loading={this.props.submitting}>
              <FormattedMessage id="CORE.NEXT" /> <i className="fi fi-right-arrow-forward" />
            </ButtonComponent>
          </div>
        </CardComponent>
      </section>
    );
  }
}
