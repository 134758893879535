// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import type { Props } from './benefit.component.types';
import styles from './benefit.module.scss';

export class BenefitComponent extends Component<Props> {
  render() {
    const selected = this.props.industries.find(x => x.id === this.props.selected);

    if (!selected) {
      return null;
    }

    const selectedName = selected.id.toUpperCase();
    const icons = {
      card: 'payment-method',
      mobile: 'hand-graving-smartphone',
      prepay: 'desktop-payment',
      voucher: 'coin',
    };

    return (
      <div className={styles.box}>
        <FormattedMessage id={`BENEFITS.${selectedName}.BENEFIT`} tagName="h4" />
        <FormattedMessage id={`BENEFITS.${selectedName}.DESCRIPTION`} tagName="p" />

        <FormattedMessage id="BENEFITS.PAYMENT_METHOD" tagName="h5" />

        <ul className={styles.options}>
          {selected.description.map((id, index) => (
            <li key={index}>{this.props.intl.formatMessage({ id })}</li>
          ))}
        </ul>

        <p className={styles.icons}>
          {selected.payments.map((payment, index) => (
            <i className={`fi fi-${icons[payment]}`} key={index} />
          ))}
        </p>

        <FormattedMessage id="BENEFITS.REGISTER_VENUE_RESPONSIBILITY_CLAUSE" tagName="h6" defaultMessage=" " />
        <a
          href={`https://www.vero.fi/syventavat-vero-ohjeet/ohje-hakusivu/62486/henkilokuntaedut-verotuksessa7/${selectedName === "HEALTH" ? "#3.2-terveydenhuolto" : "#3.6-omaehtoinen-liikunta--ja-kulttuuritoiminta:~:text=3.6.4%20Tavanomainen%20liikunta"}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="BENEFITS.TAX_SERVICES_LINK_BENEFITS" defaultMessage=" " />
        </a>
      </div>
    );
  }
}
